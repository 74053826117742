<template>
  <MainLayout fluid>
      <BatchItemsTable ref="table" v-if="item" :permFilters="[{name:'batch',value:[$route.params.id]}]" :create-button="false">

        <template #title>
          <merchant-card v-if="userIsSuperAdmin || userIsCustomerSupport" v-bind="{item}" />
          <h1>
            <i :class="['bx', resource.icon]"></i>
            {{ item.$meta.title }}
            <status class="small" v-bind="{item}" />
          </h1>
        </template>

        <template #item>

          <action-bar v-bind="{item,resource}" @action="action" />

          <div class="row row-cols-1 row-cols-md-2">
            <div class="col col-md-6 col-xl-7">
              <b-card class="mb-4">
                <template #header>
                  <div class="float-right" v-if="item.shipment_count">
                    <b-button :to="{name:'shipments',params:{filters:{'batch':[item.id]}}}" variant="info">
                        <i class='bx bxs-truck' /> {{$t('batches.shipments',{count: item.shipment_count})}}
                    </b-button>
                  </div>
                  <i class="bx bxs-component"></i>
                  {{ $t("batches.label") }}
                </template>
                <dl>
                  <div class="row row-cols-1 row-cols-xl-2">
                    <div class="col">
                      <value label="common.name" icon="bx-purchase-tag" :value="item.name"/>
                      <value label="shipments.pickup-location" icon="bx-map" :value="item.pickup_location_id">
                          <router-link v-if="item.pickup_location_id" :to="{name: `locations-show`, params: {id: item.pickup_location_id}}">
                            {{item.pickup_location_name}}
                          </router-link>
                      </value>

                    </div>
                    <div class="col">
                      <value label="shipments.contents" icon="bx-detail" :value="item.contents"/>
                      <value label="batches.packages" icon="bx-package" :value="true">
                        {{ packagesText }}
                      </value>

                    </div>
                  </div>
                </dl>

              </b-card>
            </div>
            <div class="col col-md-6 col-xl-5">

              <template>
                <b-card class="mb-4">
                  <template #header>
                    <div class="float-right">
                      <span v-for="channel in channels" :key="channel.code" class="comma">
                        <i v-if="channel.icon" :class="['small','bx',channel.icon]" />
                        {{$t(channel.label)}}</span>
                    </div>
                    <i class="bx bxs-message-detail"></i>
                    {{ $t("batches.notifications") }}
                  </template>

                  <dl>
                    <value v-if="!creditCards && !item.settings.reminder_count" label="batches.notifications" icon="bx-message-detail" :value="true">
                    <span class="text-initial" style="white-space: pre-wrap">{{item.settings.message}}</span>
                    </value>
                    <value v-else label="batches.notifications" icon="bx-message-detail" :value="true">
                      <i18n :path="creditCards ? 'batches.notification-display-cc' : 'batches.notification-display'" tag="p" class="text-initial">
                        <template #initial><strong>{{item.settings.offset}}</strong></template>
                        <template #interval><strong>{{item.settings.reminder_interval}}</strong></template>
                        <template #count><strong>{{item.settings.reminder_count}}</strong></template>
                        <template #notification>
                          <span id="notification" class="text-info text-lowercase">{{$t('batches.notification')}}</span>
                          <b-popover target="notification" triggers="hover" placement="top">
                            <span style="white-space: pre-wrap">{{item.settings.message}}</span>
                          </b-popover>
                        </template>
                        <template #reminder>
                          <span id="reminder" class="text-info text-lowercase">{{$t('batches.reminders')}}</span>
                          <b-popover target="reminder" triggers="hover" placement="top">
                            <span style="white-space: pre-wrap">{{item.settings.reminder}}</span>
                          </b-popover>
                        </template>
                        <template v-if="item.settings.custom_final_reminder" #final>
                          (<span id="final" class="text-info">{{$t('batches.final-reminder') | ucfirst}}</span>)
                          <b-popover target="final" triggers="hover" placement="top">
                            <span style="white-space: pre-wrap">{{item.settings.final_reminder}}</span>
                          </b-popover>
                        </template>
                      </i18n>
                    </value>
                  </dl>
                </b-card>
              </template>
              
            </div>
          </div>

          <h2>
            <i class="bx bx-group"></i>
            {{ $t('batches.customers') }}
          </h2>
        </template>
      </BatchItemsTable>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/Main'
import ItemTableTemplate from '@/components/ItemTableTemplate'
import ItemTable from '@/components/ItemTable'
import BatchItem from '@/resources/BatchItem'
import { capitalize } from '@/utils'
import { mapGetters, mapMutations } from 'vuex'
import { FEATURE_BATCH_CREDIT_CARDS } from '@/resources/Merchant'
import Package from '@/resources/Package'
import Value from "@/components/Value";
import ActionBar from '@/components/ActionBar.vue'
import MerchantCard from '@/components/Cards/Merchant'
import Status from '@/components/Status.vue'


const BatchItemsTable = {
  ...ItemTableTemplate,
  mixins: [ItemTable(BatchItem)]
}

export default {
  components: { 
    MainLayout, 
    BatchItemsTable,
    Value,  
    ActionBar,
    MerchantCard,
    Status,
  },
  data() {
    return {
      loadingItem: true,
      resource: this.$route.meta.resource,
      item: null,
      meta: null,
      permFilters: [
        {
          name: 'batch',
          value: [this.$route.params.id]
        }
      ]
    }
  },
  async created() {
    await this.fetchItem()

    this.$root.$on('item:refresh',this.fetchItem)
    this.$root.$on('item:refresh',this.$refs.table.refresh)
  },
  beforeDestroy(){
    this.$root.$off('item:refresh',this.$refs.table.refresh)
    this.$root.$off('item:refresh',this.fetchItem)
  },
  computed: {
    ...mapGetters({
      userFeatures: 'auth/features',
      userIsSuperAdmin: 'auth/isSuperAdmin',
      userIsCustomerSupport: 'auth/isCustomerSupport',
    }),
    creditCards() {
      return !!(this.item.features & FEATURE_BATCH_CREDIT_CARDS)
    },
    packagesText() {
      return Package.sizes.reduce((c,{label,code}) => {
        let count = this.item[`packages_${code.toLowerCase()}`]
        count && c.push(`${count} ${this.$t(label)}`)
        return c
      },[]).join(', ')
    },
    channels() {
      return this.resource.channels.filter(v => v.code & this.item.channels)
    }
  },
  methods: {
    ...mapMutations(BatchItem.plural,[
      'setFilter',
    ]),
    async fetchItem() {
      this.loadingItem = true
      try {
        this.item = await this.resource.get(this.$route.params.id)
      } catch (error) {
        //
      } finally {
        this.loadingItem = false
      }
    },
    async action(action) {
      if(!this.item.$can(action)) console.error('Invalid action',action)

      if(action === 'delete') {
        let confirm = await this.$bvModal.msgBoxConfirm(this.$t('item.confirm-delete',{item: this.$t(`${this.resource.plural}.singular`)}),{
          cancelTitle: this.$t('common.cancel'),
          okTitle: this.$t('item.delete'),
          okVariant: 'danger'
        })

        if(confirm) {
          await this.item.$delete()
          this.$router.replace({name: `${this.resource.plural}`})
        }
      }
    }
  },
  metaInfo() {
    let resource = capitalize(this.$t(`${this.resource.plural}.singular`))
    let title = this.item?.$meta?.title

    return {
      title: `${resource}: ${title}`,
    };
  },
};
</script>